  <template>
    <main class="error">
 
      <!-- HEADER TOP MENU BAR -->
        <Header></Header>


        <div class="LandingPage">
        <div class="container">
          <!-- SECTION 1 -->  
          <section  v-for="(data,index) in homePageContentData" :key="index" :class="data.templateType.slug+'-section'" > 
            <b-container>
              <Sections :sectionData="data" :sectionType="data.templateType.slug" />
            </b-container>
          </section>
          </div>
           </div>
         
      <!-- footer section -->
            <section class="footer">
              <footer>
                <cookie-law theme="dark-lime"></cookie-law>
              </footer>
              <Footer/>
            </section>
    </main>
</template>

<script>


import Header from "../components/Header";
import Footer from "../components/Footer";
import Sections from "../components/Section";
const _ = require('lodash');
import { mapGetters } from "vuex";

export default {
  name: "Error",
  components: {
    Sections,
    Header,
    Footer,
  },
  data(){
    return{
      newData:[]
    }
  },
  computed: {
    ...mapGetters(["content"]),

    homePageContentData() {
        var route=this.$route.path.split('/');
        var newArr=[]; 
        if (this.content && this.content.pageContent) {
            newArr= this.content.pageContent.filter((el)=> {
              if(el.linkedPage.length>0){
                  return el.linkedPage[0].pageSlug == 'error' ;
              }
                
            });
           
        return _.orderBy(newArr, 'orderNumber', 'asc');
        } else return {};
    },
  
  },
  
  mounted() {
    this.scrollToTop()
  },

 methods: { 

      scrollToTop() {
          window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
 
      }
  },
};
</script>
